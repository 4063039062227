.react-datepicker-popper {
	z-index: 9999 !important;
}

.react-datepicker-wrapper {
	display: block;
}

.navbar-brand img {
	height: 2em;
	vertical-align: bottom;
}

.flag-login {
	height: 3em;
}

img.flag {
	height: 1em;
	vertical-align: text-top;
}

.vertical-border {
	border-right: 1px solid #333;
}

.clickable {
	cursor: pointer;
	user-select: none;
}

body {
	background-color: #F0F8FF;
	font-family: 'Roboto', sans-serif;
}

body.BE {
	background-color: #FFF8F0;
}

#root > .navbar {
	background-color: #AACAE6;
}

.BE #root > .navbar {
	background-color: #E6AACA;
}

.max-view-height {
	height: calc(100vh - 66px);
}

.menu-option {
	display: inline-block;
	color: inherit;
	text-decoration: none;
}

.menu-option:hover {
	color: white;
	text-decoration: none;
	background-color: var(--bs-blue);
}

.btn {
	--bs-btn-border-radius: var(--bs-border-radius-sm);
}

.badge {
	--bs-badge-font-size: 1em;
}

.file-drop {
	position: relative;
	border: 5px dashed #AACAE6;
	border-radius: 1rem;
	overflow: hidden;
	height: 150px;
}

.file-release {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgb(211, 192, 216, 0.5);
}
